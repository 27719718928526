.toastContainer_e70488 {
  pointer-events: none;
  z-index: 300;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}

.toast_e70488 {
  max-width: 295px;
  font: var(--font-body-1);
  color: var(--color-static-white);
  border-radius: var(--radius);
  background: var(--color-bg-toast);
  text-align: center;
  word-break: break-word;
  pointer-events: auto;
  margin: 0 auto;
  padding: 8px 16px;
}

.ReactModal__Content[role="toast"] {
  opacity: 0;
  transition: opacity .3s ease-in-out;
}

.ReactModal__Content--after-open[role="toast"] {
  opacity: 1;
}

.ReactModal__Content--before-close[role="toast"] {
  opacity: 0;
}

body.desktop .toast_e70488 {
  box-shadow: var(--color-shadow);
  padding: 12px 16px;
}

.OXU_cq_button {
  height: 44px;
  border-radius: var(--radius);
  cursor: pointer;
  font: var(--font-label-1);
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  user-select: none;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  transition: background .2s ease-in-out;
  display: flex;
  overflow: hidden;
}

.OXU_cq_large {
  width: 100%;
}

.OXU_cq_small {
  min-width: 85px;
}

.OXU_cq_primary {
  background: var(--color-brand-default);
  color: var(--color-static-white);
  border: none;
}

.OXU_cq_primary.OXU_cq_disabled, .OXU_cq_primary:disabled {
  background: var(--color-bg-state-disabled);
  color: var(--color-type-disable);
}

.OXU_cq_primary:active {
  background: var(--color-brand-pressed);
}

.OXU_cq_secondary {
  border: 1px solid var(--color-line-border);
  color: var(--color-type-primary);
  background: none;
}

.OXU_cq_secondary.OXU_cq_disabled, .OXU_cq_secondary:disabled {
  border-color: var(--color-type-disable);
  color: var(--color-type-disable);
}

.OXU_cq_secondary:active {
  background: var(--color-overlay-neutral-pressed);
}

body.desktop .OXU_cq_primary:focus-visible {
  outline: 3px solid var(--color-overlay-brand-focused);
}

body.desktop .OXU_cq_primary:not(:disabled):not(:active):hover {
  background: var(--color-brand-hover);
}

body.desktop .OXU_cq_secondary:focus-visible {
  outline: 3px solid var(--color-overlay-neutral-focused);
}

body.desktop .OXU_cq_secondary:not(:disabled):not(:active):hover {
  background: var(--color-overlay-neutral-hover);
}

._6aRz6W_iconButton {
  border: none;
  border-color: none;
  cursor: pointer;
  background: none;
  border-radius: 6px;
  outline: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px;
  display: flex;
}

._6aRz6W_iconButton > svg {
  color: var(--color-type-secondary);
}

._6aRz6W_iconButton:active {
  background: var(--color-overlay-neutral-pressed);
}

body.desktop ._6aRz6W_iconButton:hover:not(:active) {
  background: var(--color-overlay-neutral-hover);
}

.sIGcUW_overlay {
  background: var(--color-bg-mask);
  position: fixed;
  inset: 0;
}

.ReactModal__Body--open {
  overflow: hidden;
}

.IfS0XG_overlay {
  z-index: 100;
}

.IfS0XG_modal {
  width: 600px;
  outline: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.IfS0XG_container {
  background: var(--color-bg-float-overlay);
  border-radius: 16px;
  padding: 24px;
}

.IfS0XG_header {
  font: var(--font-title-1);
  color: var(--color-type-primary);
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  display: flex;
}

.IfS0XG_header svg {
  width: 24px;
  height: 24px;
}

.IfS0XG_content {
  font: var(--font-body-2);
  color: var(--color-type-primary);
  margin-bottom: 24px;
}

.IfS0XG_footer {
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.IfS0XG_footer > * {
  flex-shrink: 1;
}

.IfS0XG_footer > button:first-child {
  margin-right: 12px;
}

.fkIcSW_overlay {
  z-index: 100;
}

.fkIcSW_modal {
  outline: none;
  position: absolute;
  top: 50%;
  left: 20px;
  right: 20px;
  transform: translate(0, -50%);
}

.fkIcSW_container {
  background: var(--color-bg-body-overlay);
  border-radius: var(--radius);
  padding: 20px;
}

.fkIcSW_content {
  text-align: center;
  font: var(--font-body-1);
  color: var(--color-type-primary);
}

.fkIcSW_footer {
  align-items: center;
  margin-top: 24px;
  display: flex;
}

.fkIcSW_footer > * {
  flex: 1;
}

.fkIcSW_footer > button:first-child {
  margin-right: 8px;
}

.fkIcSW_iframeModal {
  top: 40px;
  bottom: 40px;
  transform: none;
}

.fkIcSW_iframeModal .fkIcSW_container {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  padding: 0;
  display: flex;
}

.fkIcSW_iframeModal .fkIcSW_content {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 20px;
  display: flex;
}

.fkIcSW_iframeModal .fkIcSW_footer {
  border-top: 1px solid var(--color-line-divider);
  margin-top: 0;
  padding: 20px;
}

._1lmpHW_preview, ._1lmpHW_preview main {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
}

body {
  --radius: 8px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: auto;
  word-break: break-word;
  --color-static-white: #fff;
  --color-static-black: #000;
  --color-static-dark-type-primary: #f7f8f8;
  --color-shadow: 0 4px 8px #00000005;
  --font-headline-1: 600 28px / 32px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Arial, sans-serif;
  --font-headline-2: 600 24px / 32px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Arial, sans-serif;
  --font-title-1: 600 20px / 28px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Arial, sans-serif;
  --font-title-2: 600 18px / 26px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Arial, sans-serif;
  --font-title-3: 600 16px / 24px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Arial, sans-serif;
  --font-label-1: 500 16px / 24px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Arial, sans-serif;
  --font-label-2: 500 14px / 20px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Arial, sans-serif;
  --font-label-3: 500 12px / 16px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Arial, sans-serif;
  --font-body-1: 400 16px / 24px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Arial, sans-serif;
  --font-body-2: 400 14px / 20px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Arial, sans-serif;
  --font-body-3: 400 12px / 16px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Arial, sans-serif;
}

body._1lmpHW_light {
  --color-neutral-0: #0000;
  --color-neutral-10: #191c1d;
  --color-neutral-20: #2d3132;
  --color-neutral-50: #747778;
  --color-neutral-80: #c4c7c7;
  --color-neutral-90: #e0e3e3;
  --color-neutral-95: #eff1f1;
  --color-neutral-100: #fff;
  --color-neutral-variant-60: #928f9a;
  --color-neutral-variant-80: #e5e1ec;
  --color-danger-30: #930006;
  --color-danger-40: #ba1b1b;
  --color-danger-50: #dd3730;
  --color-success-60: #32873d;
  --color-success-70: #68be6c;
  --color-success-80: #83da85;
  --color-success-90: #9fe79f;
  --color-brand-30: #4300da;
  --color-brand-40: #5d34f2;
  --color-brand-50: #7958ff;
  --color-alert-70: #eb9918;
  --color-alert-99: #fff5eb;
  --color-type-primary: var(--color-neutral-10);
  --color-type-secondary: var(--color-neutral-50);
  --color-type-disable: #191c1d59;
  --color-type-link: var(--color-brand-default);
  --color-line-divider: #191c1d1f;
  --color-line-border: var(--color-neutral-80);
  --color-line-border-transparent: #191c1d40;
  --color-bg-body-base: var(--color-neutral-95);
  --color-bg-body: var(--color-neutral-100);
  --color-bg-layer-2: var(--color-neutral-95);
  --color-bg-body-overlay: var(--color-neutral-100);
  --color-bg-float-base: var(--color-neutral-variant-80);
  --color-bg-float: var(--color-neutral-100);
  --color-bg-float-overlay: var(--color-neutral-100);
  --color-bg-mask: #0006;
  --color-bg-toast: var(--color-neutral-20);
  --color-bg-state-unselected: var(--color-neutral-90);
  --color-bg-state-disabled: #191c1d14;
  --color-brand-default: var(--color-brand-40);
  --color-brand-hover: var(--color-brand-50);
  --color-brand-pressed: var(--color-brand-30);
  --color-danger-default: var(--color-danger-40);
  --color-danger-hover: var(--color-danger-50);
  --color-danger-pressed: var(--color-danger-30);
  --color-success-default: var(--color-success-70);
  --color-success-hover: var(--color-success-80);
  --color-success-pressed: var(--color-success-60);
  --color-success-disable: var(--color-success-90);
  --color-overlay-neutral-hover: #191c1d14;
  --color-overlay-neutral-pressed: #191c1d1f;
  --color-overlay-neutral-focused: #191c1d29;
  --color-overlay-danger-hover: #ba1b1b14;
  --color-overlay-danger-pressed: #ba1b1b1f;
  --color-overlay-danger-focused: #ba1b1b29;
  --color-overlay-brand-hover: #5d34f214;
  --color-overlay-brand-pressed: #5d34f21f;
  --color-overlay-brand-focused: #5d34f229;
}

body._1lmpHW_dark {
  --color-neutral-0: #fff;
  --color-neutral-10: #f7f8f8;
  --color-neutral-20: #eff1f1;
  --color-neutral-50: #a9acac;
  --color-neutral-80: #5c5f60;
  --color-neutral-90: #444748;
  --color-neutral-95: #2d3132;
  --color-neutral-99: #191c1d;
  --color-neutral-100: #000;
  --color-neutral-variant-60: #928f9a;
  --color-neutral-variant-80: #5f5d67;
  --color-neutral-variant-90: #47464e;
  --color-danger-30: #ffdad4;
  --color-danger-40: #ffb4a9;
  --color-danger-50: #ff897a;
  --color-danger-60: #ff5449;
  --color-danger-70: #dd3730;
  --color-danger-80: #ba1b1b;
  --color-success-60: #4ea254;
  --color-success-70: #32873d;
  --color-success-80: #116d26;
  --color-success-90: #005314;
  --color-brand-30: #e6deff;
  --color-brand-40: #cabeff;
  --color-brand-50: #af9eff;
  --color-brand-70: #7958ff;
  --color-surface: var(--color-neutral-99);
  --color-surface-2: linear-gradient(0deg, #cabeff14, #cabeff14), linear-gradient(0deg, #c4c7c705, #c4c7c705), #191c1d;
  --color-surface-4: linear-gradient(0deg, #cabeff24, #cabeff24), linear-gradient(0deg, #c4c7c705, #c4c7c705), #191c1d;
  --color-alert-70: #a76900;
  --color-alert-99: #2b1700;
  --color-type-primary: var(--color-neutral-10);
  --color-type-secondary: var(--color-neutral-50);
  --color-type-disable: #f7f8f859;
  --color-type-link: var(--color-brand-default);
  --color-line-divider: #f7f8f824;
  --color-line-border: var(--color-neutral-80);
  --color-line-border-transparent: #f7f8f833;
  --color-bg-body-base: var(--color-neutral-100);
  --color-bg-body: var(--color-surface);
  --color-bg-body-overlay: var(--color-surface-2);
  --color-bg-layer-2: var(--color-surface-4);
  --color-bg-float-base: var(--color-neutral-100);
  --color-bg-float: var(--color-surface-2);
  --color-bg-float-overlay: var(--color-surface-4);
  --color-bg-mask: #0009;
  --color-bg-toast: var(--color-neutral-80);
  --color-bg-state-unselected: var(--color-neutral-90);
  --color-bg-state-disabled: #f7f8f814;
  --color-brand-default: var(--color-brand-70);
  --color-brand-hover: var(--color-brand-50);
  --color-brand-pressed: var(--color-brand-30);
  --color-danger-default: var(--color-danger-70);
  --color-danger-hover: var(--color-danger-60);
  --color-danger-pressed: var(--color-danger-80);
  --color-success-default: var(--color-success-70);
  --color-success-hover: var(--color-success-60);
  --color-success-pressed: var(--color-success-80);
  --color-success-disable: var(--color-success-90);
  --color-overlay-neutral-hover: #f7f8f814;
  --color-overlay-neutral-pressed: #f7f8f81f;
  --color-overlay-neutral-focused: #f7f8f829;
  --color-overlay-danger-hover: #dd373014;
  --color-overlay-danger-pressed: #dd37301f;
  --color-overlay-danger-focused: #dd373029;
  --color-overlay-brand-hover: #cabeff14;
  --color-overlay-brand-pressed: #cabeff1f;
  --color-overlay-brand-focused: #cabeff29;
}

._1lmpHW_container {
  color: var(--color-type-primary);
  flex-direction: column;
  justify-content: normal;
  align-items: center;
  display: flex;
  position: absolute;
  inset: 0;
  overflow: auto;
}

._1lmpHW_main {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

._1lmpHW_placeHolder {
  flex: 1;
}

body.mobile {
  --max-width: 360px;
  background: var(--color-bg-body);
}

body.mobile ._1lmpHW_container {
  background: var(--color-bg-body);
  font: var(--font-body-1);
}

body.mobile ._1lmpHW_main {
  background: var(--color-bg-body);
  flex: 1;
  align-self: stretch;
  padding: 16px 20px;
  position: relative;
}

body.desktop {
  --max-width: 400px;
  background: var(--color-bg-float-base);
}

body.desktop ._1lmpHW_container {
  background: var(--color-bg-float-base);
  font: var(--font-body-2);
}

body.desktop ._1lmpHW_main {
  width: 640px;
  min-height: 640px;
  background: var(--color-bg-float);
  box-shadow: var(--color-shadow);
  border-radius: 16px;
  padding: 24px;
  position: relative;
}

.EmK7ba_overlay {
  background-color: var(--color-bg-mask);
  z-index: 300;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  inset: 0;
}

.EmK7ba_loadingIcon {
  color: var(--color-type-primary);
  animation: EmK7ba_rotating 1s steps(12, end) infinite;
}

.EmK7ba_container {
  width: 60px;
  height: 60px;
  border-radius: var(--radius);
  background-color: var(--color-bg-toast);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.EmK7ba_container .EmK7ba_loadingIcon {
  color: var(--color-static-dark-type-primary);
}

@keyframes EmK7ba_rotating {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.CKtIva_container {
  width: 100%;
  max-width: var(--max-width);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  display: flex;
}

.CKtIva_connector {
  margin-bottom: 16px;
}

.CKtIva_connector > img {
  width: 96px;
  height: 96px;
  object-fit: contain;
  object-position: center;
}

.CKtIva_message {
  text-align: center;
  font: var(--font-body-2);
  color: var(--color-type-secondary);
}

.C4P11G_wrapper {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  display: flex;
}

.C4P11G_connectorContainer {
  flex: 1;
}

.tkq4Sq_wrapper {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  display: flex;
}

.tkq4Sq_wrapper img {
  width: 96px;
  height: 96px;
  object-fit: contain;
  object-position: center;
  margin-bottom: 16px;
}

.mD_jbq_navBar {
  width: 100%;
  min-height: 44px;
  color: var(--color-type-primary);
  justify-content: center;
  align-items: center;
  padding: 12px 40px;
  display: flex;
  position: relative;
}

.mD_jbq_navBar > svg {
  fill: current-color;
}

.mD_jbq_navBar .mD_jbq_title {
  font: var(--font-title-3);
}

.mD_jbq_backButton {
  font: var(--font-label-2);
  cursor: pointer;
  align-items: center;
  display: flex;
  position: absolute;
  top: 50%;
  left: -8px;
  transform: translateY(-50%);
}

body.mobile .mD_jbq_backButton > span {
  display: none;
}

body.desktop .mD_jbq_backButton:hover {
  text-decoration: underline;
}

.-\37 GsHW_wrapper {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  display: flex;
}

.-\37 GsHW_wrapper .-\37 GsHW_container {
  width: 100%;
  max-width: var(--max-width);
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  display: flex;
}

.-\37 GsHW_wrapper .-\37 GsHW_title {
  text-align: center;
}

.-\37 GsHW_wrapper .-\37 GsHW_message {
  font: var(--font-body-2);
  color: var(--color-type-secondary);
  text-align: center;
}

body.mobile .-\37 GsHW_title {
  font: var(--font-headline-1);
  color: var(--color-type-primary);
  margin-bottom: 16px;
}

body.mobile .-\37 GsHW_backButton {
  width: 100%;
  max-width: var(--max-width);
  margin: 0 auto 16px;
}

body.desktop .-\37 GsHW_title {
  font: var(--font-headline-2);
  color: var(--color-type-primary);
  margin-bottom: 8px;
}

body.desktop .-\37 GsHW_backButton {
  width: 100%;
  max-width: var(--max-width);
  margin: 0 auto 48px;
}

.u6h5rG_error {
  font: var(--font-body-2);
  color: var(--color-danger-default);
}

.eHQ0hG_wrapper {
  border: 1px solid var(--color-line-border);
  border-radius: var(--radius);
  height: 44px;
  align-items: center;
  transition-property: outline, border;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
  display: flex;
  position: relative;
  overflow: hidden;
}

.eHQ0hG_wrapper .eHQ0hG_actionButton {
  color: var(--color-type-secondary);
  width: 24px;
  height: 24px;
  display: none;
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
}

.eHQ0hG_wrapper input {
  caret-color: var(--color-brand-default);
  font: var(--font-body-1);
  color: var(--color-type-primary);
  background: none;
  flex: 1;
  align-self: stretch;
  padding: 0 16px;
}

.eHQ0hG_wrapper input::placeholder {
  color: var(--color-type-secondary);
}

.eHQ0hG_wrapper:focus-within {
  border: 1px solid var(--color-brand-default);
}

.eHQ0hG_wrapper:focus-within .eHQ0hG_actionButton {
  display: block;
}

.eHQ0hG_wrapper:focus-within input {
  outline: none;
  padding-right: 40px;
}

.eHQ0hG_wrapper.eHQ0hG_error {
  border: 1px solid var(--color-danger-default);
}

.eHQ0hG_errorMessage {
  margin-top: 8px;
}

body.desktop .eHQ0hG_wrapper {
  border-radius: 6px;
  outline: 3px solid #0000;
}

body.desktop .eHQ0hG_wrapper input {
  font: var(--font-body-2);
}

body.desktop .eHQ0hG_wrapper .eHQ0hG_actionButton {
  width: 22px;
  height: 22px;
}

body.desktop .eHQ0hG_wrapper.eHQ0hG_error {
  border: 1px solid var(--color-danger-default);
}

body.desktop .eHQ0hG_wrapper:focus-within {
  border: 1px solid var(--color-brand-default);
  outline-color: var(--color-overlay-brand-focused);
}

.nt_K4G_countryCodeSelector {
  color: var(--color-type-primary);
  width: auto;
  background: none;
  border: none;
  align-items: center;
  margin-left: 16px;
  margin-right: 4px;
  display: flex;
  position: relative;
}

.nt_K4G_countryCodeSelector > select {
  appearance: none;
  width: 100%;
  height: 100%;
  background: none;
  border: none;
  outline: none;
  font-size: 0;
  position: absolute;
}

.nt_K4G_countryCodeSelector + input {
  padding-left: 4px;
}

body.mobile .nt_K4G_countryCodeSelector, body.mobile .nt_K4G_countryCodeSelector > select option {
  font: var(--font-label-1);
}

body.mobile .nt_K4G_countryCodeSelector > svg {
  color: var(--color-brand-default);
  width: 16px;
  height: 16px;
  margin-left: 4px;
}

body.desktop .nt_K4G_countryCodeSelector, body.desktop .nt_K4G_countryCodeSelector > select option {
  font: var(--font-body-2);
}

body.desktop .nt_K4G_countryCodeSelector > svg {
  color: var(--color-type-secondary);
  width: 20px;
  height: 20px;
  margin-left: 8px;
}

.CE9sHW_checkbox {
  align-items: center;
  display: inline-flex;
  position: relative;
}

.CE9sHW_checkbox .CE9sHW_icon {
  margin-right: 8px;
}

.CE9sHW_checkbox .CE9sHW_icon > * {
  display: none;
}

.CE9sHW_checkbox .CE9sHW_icon > :nth-child(1) {
  color: var(--color-type-secondary);
}

.CE9sHW_checkbox .CE9sHW_icon > :nth-child(2) {
  color: var(--color-brand-default);
}

.CE9sHW_checkbox input {
  width: 18px;
  height: 18px;
  opacity: 0;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.CE9sHW_checkbox input:not(:checked):not(:disabled) ~ .CE9sHW_icon > :nth-child(1), .CE9sHW_checkbox input:checked:not(:disabled) ~ .CE9sHW_icon > :nth-child(2), .CE9sHW_checkbox input:checked:not(:disabled) ~ .CE9sHW_icon > :nth-child(3) {
  display: block;
}

.Umsodq_link {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.Umsodq_link.Umsodq_primary {
  color: var(--color-brand-default);
  font: var(--font-label-2);
  border-radius: 4px;
  padding: 4px 2px;
  text-decoration: none;
}

.Umsodq_link.Umsodq_primary:active {
  background: var(--color-overlay-brand-pressed);
}

.Umsodq_link.Umsodq_secondary {
  color: var(--color-type-secondary);
  text-decoration: underline;
}

body.desktop .Umsodq_link.Umsodq_primary:hover {
  background: var(--color-overlay-brand-hover);
}

body.desktop .Umsodq_link.Umsodq_primary:focus-visible {
  outline: 1px solid var(--color-overlay-brand-focused);
}

body.desktop .Umsodq_link.Umsodq_secondary:hover, body.desktop .Umsodq_link.Umsodq_secondary:active {
  color: var(--color-brand-default);
}

.lWVv8a_terms {
  width: 100%;
  -webkit-user-select: none;
  user-select: none;
  align-items: center;
  display: flex;
}

.lWVv8a_checkBox {
  fill: var(--color-type-secondary);
  margin-right: 8px;
}

.lWVv8a_content, .lWVv8a_content .lWVv8a_link {
  font: var(--font-body-2);
  color: var(--color-type-secondary);
}

.lWVv8a_errorMessage {
  margin-top: 8px;
}

._BtBWW_hidden {
  display: none;
}

._BtBWW_iframe {
  display: block;
}

.FTV6Tq_form {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.FTV6Tq_form > * {
  width: 100%;
}

.FTV6Tq_form .FTV6Tq_inputField, .FTV6Tq_form .FTV6Tq_terms, .FTV6Tq_form .FTV6Tq_switch {
  margin-bottom: 16px;
}

.FTV6Tq_form .FTV6Tq_switch {
  margin-top: -4px;
  display: block;
}

.FTV6Tq_form .FTV6Tq_formFields {
  margin-bottom: 32px;
}

body.desktop .FTV6Tq_formFields {
  margin-bottom: 8px;
}

.QKOMYq_wrapper {
  flex: 1;
  align-self: stretch;
}

.QKOMYq_container {
  width: 100%;
  max-width: var(--max-width);
  margin: 8px auto 0;
}

.QKOMYq_description {
  font: var(--font-body-2);
  color: var(--color-type-secondary);
  margin-bottom: 24px;
}

body.mobile .QKOMYq_container {
  margin-top: 8px;
}

body.mobile .QKOMYq_title {
  font: var(--font-headline-1);
  color: var(--color-type-primary);
  margin-bottom: 24px;
}

body.desktop .QKOMYq_container {
  margin-top: 48px;
}

body.desktop .QKOMYq_title {
  font: var(--font-headline-2);
  color: var(--color-type-primary);
  margin-bottom: 16px;
}

.HL9e4q_passcode {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.HL9e4q_passcode input {
  width: 44px;
  height: 44px;
  border: 1px solid var(--color-line-border);
  text-align: center;
  font: var(--font-label-1);
  color: var(--color-type-primary);
  caret-color: var(--color-brand-default);
  background: none;
  border-radius: 8px;
}

.HL9e4q_passcode input:focus {
  border: 1px solid var(--color-brand-default);
  outline: none;
}

.HL9e4q_passcode input::placeholder {
  color: var(--color-type-secondary);
}

.HL9e4q_passcode + .HL9e4q_errorMessage {
  margin-top: 8px;
}

body.desktop .HL9e4q_passcode input {
  font: var(--font-body-2);
  outline: 3px solid #0000;
}

body.desktop .HL9e4q_passcode input:focus {
  border: 1px solid var(--color-brand-default);
  outline-color: var(--color-overlay-brand-focused);
}

.dh4rTq_form {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.dh4rTq_form > * {
  width: 100%;
}

.dh4rTq_form .dh4rTq_inputField {
  margin-bottom: 32px;
}

.dh4rTq_form .dh4rTq_inputField.dh4rTq_withError {
  margin-bottom: 8px;
}

.dh4rTq_form .dh4rTq_message {
  font: var(--font-body-2);
}

.dh4rTq_form .dh4rTq_message > span {
  color: var(--color-brand-default);
}

body.mobile .dh4rTq_message {
  color: var(--color-type-primary);
}

body.desktop .dh4rTq_message {
  color: var(--color-type-secondary);
}

.fswYpW_wrapper {
  flex: 1;
  align-self: stretch;
}

.fswYpW_container {
  width: 100%;
  max-width: var(--max-width);
  margin: 8px auto 0;
}

.fswYpW_title {
  margin-bottom: 4px;
}

.fswYpW_detail {
  font: var(--font-body-2);
  color: var(--color-type-secondary);
  margin-bottom: 24px;
}

body.mobile .fswYpW_container {
  margin-top: 8px;
}

body.mobile .fswYpW_title {
  font: var(--font-headline-1);
  color: var(--color-type-primary);
}

body.desktop .fswYpW_container {
  margin-top: 48px;
}

body.desktop .fswYpW_title {
  font: var(--font-headline-2);
  color: var(--color-type-primary);
}

.adSMwW_form {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.adSMwW_form > * {
  width: 100%;
}

.adSMwW_form .adSMwW_inputField {
  margin-bottom: 16px;
}

.adSMwW_form .adSMwW_formFields {
  margin-bottom: 32px;
}

.adSMwW_form .adSMwW_terms {
  margin-bottom: 16px;
}

body.desktop .adSMwW_formFields {
  margin-bottom: 8px;
}

.HhL-Ma_wrapper {
  flex: 1;
  align-self: stretch;
}

.HhL-Ma_container {
  width: 100%;
  max-width: var(--max-width);
  margin: 8px auto 0;
}

body.mobile .HhL-Ma_container {
  margin-top: 8px;
}

body.mobile .HhL-Ma_title {
  font: var(--font-headline-1);
  color: var(--color-type-primary);
  margin-bottom: 24px;
}

body.desktop .HhL-Ma_container {
  margin-top: 48px;
}

body.desktop .HhL-Ma_title {
  font: var(--font-headline-2);
  color: var(--color-type-primary);
  margin-bottom: 16px;
}

.m2nksG_form {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.m2nksG_form > * {
  width: 100%;
}

.m2nksG_form .m2nksG_inputField {
  margin-bottom: 16px;
}

.m2nksG_form .m2nksG_formErrors {
  margin-top: -8px;
  margin-bottom: 16px;
}

.wgSO9W_wrapper {
  flex: 1;
  align-self: stretch;
}

.wgSO9W_container {
  width: 100%;
  max-width: var(--max-width);
  margin: 8px auto 0;
}

body.mobile .wgSO9W_container {
  margin-top: 8px;
}

body.mobile .wgSO9W_title {
  font: var(--font-headline-1);
  color: var(--color-type-primary);
  margin-bottom: 24px;
}

body.desktop .wgSO9W_container {
  margin-top: 48px;
}

body.desktop .wgSO9W_title {
  font: var(--font-headline-2);
  color: var(--color-type-primary);
  margin-bottom: 16px;
}

.YCHB-G_form {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.YCHB-G_form > * {
  width: 100%;
}

.YCHB-G_form .YCHB-G_inputField {
  margin-bottom: 16px;
}

.YCHB-G_form .YCHB-G_formFields {
  margin-bottom: 32px;
}

.YCHB-G_form .YCHB-G_terms {
  margin-bottom: 16px;
}

.YCHB-G_form .YCHB-G_formErrors {
  margin-top: -8px;
  margin-bottom: 16px;
}

body.desktop .YCHB-G_formFields {
  margin-bottom: 8px;
}

.iJtG_q_wrapper {
  flex: 1;
  align-self: stretch;
}

.iJtG_q_container {
  width: 100%;
  max-width: var(--max-width);
  margin: 8px auto 0;
}

body.mobile .iJtG_q_container {
  margin-top: 8px;
}

body.mobile .iJtG_q_title {
  font: var(--font-headline-1);
  color: var(--color-type-primary);
  margin-bottom: 24px;
}

body.desktop .iJtG_q_container {
  margin-top: 48px;
}

body.desktop .iJtG_q_title {
  font: var(--font-headline-2);
  color: var(--color-type-primary);
  margin-bottom: 16px;
}

.Uwl8ga_container {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.Uwl8ga_logo {
  height: 60px;
  width: auto;
  object-fit: contain;
  object-position: center;
}

.Uwl8ga_headline {
  text-align: center;
  color: var(--color-type-primary);
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

body.mobile .Uwl8ga_container {
  height: 15vh;
  min-height: 92px;
  max-height: 148px;
}

body.mobile .Uwl8ga_logo:not(:last-child) {
  margin-bottom: 8px;
}

body.mobile .Uwl8ga_headline {
  font: var(--font-title-1);
}

body.desktop .Uwl8ga_container {
  min-height: 96px;
}

body.desktop .Uwl8ga_logo:not(:last-child) {
  margin-bottom: 16px;
}

body.desktop .Uwl8ga_headline {
  font: var(--font-title-2);
}

.b3_kzq_notification {
  font: var(--font-body-2);
  color: var(--color-type-primary);
  align-items: center;
  margin: 0 auto 8px;
  padding: 12px 16px;
  display: flex;
}

.b3_kzq_notification .b3_kzq_icon {
  width: 20px;
  height: 20px;
  margin-right: 12px;
}

.b3_kzq_notification.b3_kzq_alert {
  background: var(--color-alert-99);
}

.b3_kzq_notification.b3_kzq_alert .b3_kzq_icon {
  color: var(--color-alert-70);
}

.b3_kzq_notification.b3_kzq_info {
  background: var(--color-neutral-variant-80);
}

.b3_kzq_notification.b3_kzq_info .b3_kzq_icon {
  color: var(--color-neutral-variant-60);
}

.b3_kzq_message {
  flex: 1;
  margin-right: 16px;
}

.b3_kzq_link {
  max-width: 20%;
}

body.desktop .b3_kzq_notification {
  border-radius: var(--radius);
}

.LH6U0W_appNotification {
  max-width: 520px;
  position: absolute;
}

body.mobile .LH6U0W_appNotification {
  top: 24px;
  left: 20px;
  right: 20px;
}

body.desktop .LH6U0W_appNotification {
  width: max-content;
  top: -24px;
  left: 50%;
  transform: translate(-50%, -100%);
}

@media screen and (max-height: 820px) {
  body.desktop .LH6U0W_appNotification {
    top: 24px;
    transform: translate(-50%);
  }
}

.Wo_ieW_wrapper {
  width: 100%;
  max-width: var(--max-width);
  flex-direction: column;
  flex: 1;
  justify-content: normal;
  align-self: stretch;
  align-items: normal;
  margin: 0 auto;
  display: flex;
}

.Wo_ieW_wrapper .Wo_ieW_primarySignIn {
  margin-bottom: 20px;
}

.Wo_ieW_wrapper .Wo_ieW_otherMethodsLink {
  margin-bottom: 24px;
}

.Wo_ieW_wrapper .Wo_ieW_createAccount {
  text-align: center;
  margin-top: 24px;
}

.Wo_ieW_wrapper .Wo_ieW_placeHolder {
  flex: 1;
}

.Wo_ieW_placeholderTop {
  flex: 3;
}

.Wo_ieW_placeholderBottom {
  flex: 5;
}

body.mobile .Wo_ieW_header {
  margin-top: 12px;
  margin-bottom: 48px;
}

body.mobile .Wo_ieW_primarySocial {
  margin-bottom: 32px;
}

body.mobile .Wo_ieW_divider {
  margin-bottom: 20px;
}

body.mobile .Wo_ieW_createAccount {
  padding-bottom: env(safe-area-inset-bottom);
}

body.desktop .Wo_ieW_header {
  margin-bottom: 24px;
}

body.desktop .Wo_ieW_primarySocial {
  margin-bottom: 48px;
}

body.desktop .Wo_ieW_placeHolder {
  flex: 0;
}

body.desktop .Wo_ieW_divider {
  margin-bottom: 16px;
}

.Zfm1pa_divider {
  font: var(--font-body-2);
  color: var(--color-type-secondary);
  align-items: center;
  display: flex;
}

.Zfm1pa_divider .Zfm1pa_line {
  height: 1px;
  background: var(--color-line-divider);
  flex: 1;
}

.Zfm1pa_divider .Zfm1pa_line:first-child {
  margin-right: 16px;
}

.Zfm1pa_divider .Zfm1pa_line:last-child {
  margin-left: 16px;
}

.RjiTGa_textLink {
  text-align: center;
}

.RjiTGa_textLink .RjiTGa_signInMethodLink {
  font-size: inherit;
}

.RjiTGa_methodsLinkList {
  justify-content: center;
  align-items: center;
  display: flex;
}

.RjiTGa_methodsLinkList .RjiTGa_signInMethodLink {
  padding: 0 16px;
  position: relative;
}

.RjiTGa_methodsLinkList .RjiTGa_signInMethodLink:after {
  content: "";
  width: 1px;
  height: 12px;
  background-color: var(--color-brand-default);
  opacity: .6;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.RjiTGa_methodsLinkList .RjiTGa_signInMethodLink:first-child {
  padding-left: 0;
}

.RjiTGa_methodsLinkList .RjiTGa_signInMethodLink:last-child {
  padding-right: 0;
}

.RjiTGa_methodsLinkList .RjiTGa_signInMethodLink:last-child:after {
  content: none;
}

body.desktop .RjiTGa_methodsLinkList .RjiTGa_signInMethodLink {
  padding: 0 20px;
  position: relative;
}

body.desktop .RjiTGa_methodsLinkList .RjiTGa_signInMethodLink:after {
  content: "";
  width: 1px;
  height: 12px;
  background-color: var(--color-brand-default);
  opacity: .6;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

body.desktop .RjiTGa_methodsLinkList .RjiTGa_signInMethodLink:first-child {
  padding-left: 0;
}

body.desktop .RjiTGa_methodsLinkList .RjiTGa_signInMethodLink:last-child {
  padding-right: 0;
}

body.desktop .RjiTGa_methodsLinkList .RjiTGa_signInMethodLink:last-child:after {
  content: none;
}

.wltJTG_content {
  background: var(--color-bg-float-overlay);
  box-shadow: var(--color-shadow);
  border-radius: var(--radius);
  border: 1px solid var(--color-line-divider);
}

.wltJTG_content:focus {
  outline: none;
}

.wltJTG_overlay {
  background: none;
  position: fixed;
  inset: 0;
}

.wltJTG_list {
  margin: 0;
  padding: 6px 4px;
}

.uldC3a_item {
  border-radius: var(--radius);
  font: var(--font-body-2);
  color: var(--color-type-primary);
  cursor: pointer;
  align-items: center;
  padding: 6px 8px;
  list-style: none;
  display: flex;
  overflow: hidden;
}

.uldC3a_item:hover {
  background: var(--color-overlay-neutral-hover);
}

.dFDs0G_socialDropDown {
  min-width: 208px;
  transform-origin: 12px bottom;
  opacity: 0;
  transition: transform .1s, opacity .1s;
  position: absolute;
  transform: translateY(-100%)scale(0);
}

.dFDs0G_socialLogo {
  width: 24px;
  height: 24px;
  margin-right: 16px;
}

.ReactModal__Content--after-open.dFDs0G_socialDropDown {
  opacity: 1;
  transform: translateY(-100%)scale(1);
}

.ReactModal__Content--before-close.dFDs0G_socialDropDown {
  opacity: 0;
  transform: translateY(-100%)scale(0);
}

.srqWIG_socialButton {
  background: var(--color-bg-layer-2);
  width: 40px;
  height: 40px;
  cursor: pointer;
  border: none;
  border-radius: 50%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.srqWIG_inverse {
  background: var(--color-type-primary);
}

.srqWIG_icon {
  object-fit: contain;
  object-position: center;
  width: 24px;
  height: 24px;
  overflow: hidden;
}

.rOIJdW_socialIconList {
  justify-content: center;
  align-items: center;
  display: flex;
}

.rOIJdW_socialButton {
  margin-right: 32px;
}

.rOIJdW_socialButton:last-child {
  margin-right: 0;
}

.rOIJdW_moreButton {
  width: 40px;
  height: 40px;
  background: var(--color-bg-layer-2);
  border-radius: 50%;
}

.rOIJdW_moreButton > svg {
  width: 24px;
  height: 24px;
}

.drawer_0edc42 {
  padding-bottom: env(safe-area-inset-bottom);
  outline: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.container_0edc42 {
  background: var(--color-bg-body-overlay);
  max-height: 411px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  flex-direction: column;
  justify-content: normal;
  align-items: stretch;
  padding: 20px 20px 0;
  display: flex;
}

.header_0edc42 {
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 16px;
  display: flex;
}

.header_0edc42 svg {
  color: var(--color-type-secondary);
  width: 20px;
  height: 20px;
}

.content_0edc42 {
  flex: 1;
  padding-bottom: 20px;
  overflow-y: auto;
}

.content_0edc42::-webkit-scrollbar {
  display: none;
}

.ReactModal__Content[role="popup"] {
  transition: transform .3s ease-in-out;
  transform: translateY(100%);
}

.ReactModal__Content--after-open[role="popup"] {
  transform: translateY(0);
}

.ReactModal__Content--before-close[role="popup"] {
  transform: translateY(100%);
}

.PMA8Ha_socialButton {
  justify-content: normal;
  padding: 0 16px;
}

.PMA8Ha_icon {
  width: 24px;
  height: 24px;
  object-fit: contain;
  object-position: center;
  overflow: hidden;
}

.PMA8Ha_name {
  flex: 1;
  align-items: center;
  margin-left: -24px;
  display: flex;
  overflow: hidden;
}

.PMA8Ha_name span {
  text-overflow: ellipsis;
  overflow: hidden;
}

.PMA8Ha_name .PMA8Ha_placeHolder {
  flex: 1;
}

.PMA8Ha_name .PMA8Ha_placeHolder:first-child {
  min-width: 40px;
}

._1ILtqG_socialLinkList {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

._1ILtqG_socialLinkButton {
  margin-bottom: 16px;
}

._1ILtqG_expandIcon > svg {
  width: 20px;
  height: 20px;
}

._1ILtqG_expandIcon._1ILtqG_expanded {
  transform: rotate(180deg);
}

.pxDUbq_terms {
  margin-bottom: 16px;
}

.WOKnOG_wrapper {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  display: flex;
}

.WOKnOG_connectorContainer {
  flex: 1;
}

.YnZBYa_container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.YnZBYa_container > * {
  width: 100%;
}

.YnZBYa_desc {
  font: var(--font-body-2);
  color: var(--color-type-secondary);
  text-align: left;
}

.YnZBYa_desc:not(:first-child) {
  margin-top: 32px;
}

body.mobile .YnZBYa_desc {
  margin-bottom: 8px;
}

body.desktop .YnZBYa_desc {
  margin-bottom: 16px;
}

.Yut7pG_wrapper {
  flex: 1;
  align-self: stretch;
}

.Yut7pG_container {
  width: 100%;
  max-width: var(--max-width);
  margin: 0 auto;
}

body.mobile .Yut7pG_container {
  margin-top: 8px;
}

body.desktop .Yut7pG_container {
  margin-top: 48px;
}

body.desktop .Yut7pG_title {
  font: var(--font-headline-2);
  color: var(--color-type-primary);
  margin-bottom: 32px;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  text-underline-offset: 2px;
}

input {
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
}

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

:focus-visible {
  outline: solid 1px var(--color-brand-default);
}

/*# sourceMappingURL=index.29d4af02.css.map */
